import {
    Box,
    Typography,
    TextField,
    Button,
    Tooltip,
    IconButton,
    Chip,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import Connection from '../../../../../service/Connection';
import { LoadingButton } from '@mui/lab';
import { UploadFileTwoTone } from '@mui/icons-material';
import { Avatar, Card, Divider, Progress, Skeleton, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import RedSchoolIcon from '../../../../assets/iconos/Escuela_Rojo.svg';
import YellowSchoolIcon from '../../../../assets/iconos/Escuela_Amarillo.svg';
import GreenSchoolIcon from '../../../../assets/iconos/Escuela_Verde.svg';
import BlueSchoolIcon from '../../../../assets/iconos/Escuela_Azul.svg';

import childrenIcon from '../../../../assets/iconos/children.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Map, Marker } from 'pigeon-maps';
import { useFeedback } from '../../../../../hooks';
import { useShowContainer } from '../../../../services/Container';
import { getLevelName, getTurnLevel } from '../../../../../libs/utils';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const phoneRegex = /^[0-9]{10}$/;

const validationSchema = Yup.object({
    identifier: Yup.string()
        .required('Campo requerido')
        .test('is_valid', 'Debe ser un Folio válido o número de teléfono válido', (value) => {
            return uuidRegex.test(value) || phoneRegex.test(value);
        }),
});

export const Following = () => {
    const feedbackApp = useFeedback();
    const { showContainer, setShowContainer, uuid } = useShowContainer();

    const [loading, setLoading] = useState(false);
    const [Prescriptions, setPrescriptions] = useState([]);

    const isMobile = useMediaQuery('(max-width:1096px)');
    const isMediumScreen = useMediaQuery("(min-width:1100px) and (max-width:1625px)");

    const handleExportPDF = (postulationId) => {
        Connection.getPostulationsExportable('assignment-voucher', 'pdf', {
            id_postulacion: postulationId,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ficha_preinscripcion.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                feedbackApp.showFeedback({
                    title: '¡Ficha Descargada con Éxito! 🎉  Recuerda llevarla contigo a las instalaciones de la institución.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: '¡Ups! Algo salió mal',
                    severity: 'error',
                });
            });
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        setSubmitting(true);
        setPrescriptions([]);
        let finalValues = {};
        if (uuidRegex.test(values.identifier)) {
            finalValues = `id_preinscripcion=${values.identifier}`;
        } else if (phoneRegex.test(values.identifier)) {
            finalValues = `cellphone=${values.identifier}`;
        }

        Connection.getPendingPrescriptionsById(finalValues)
            .then((pre) => {
                let prescriptions = pre.data.data;

                const postulationsPromises = prescriptions.map((prescription) =>
                    Connection.getPendingPostulationsById(
                        prescription.preinscription_id,
                        finalValues
                    ).then((postulationResponse) => ({
                        prescription,
                        postulations: postulationResponse.data.data,
                    }))
                );
                return Promise.all(postulationsPromises);
            })
            .then((prescriptionsWithPostulations) => {
                const schoolPromises = prescriptionsWithPostulations.map((item) =>
                    Promise.all(
                        item.postulations.map((postulation) =>
                            Connection.getSchoolsByPostulations(
                                postulation.postulation_id,
                                finalValues
                            ).then((schoolResponse) => ({
                                ...postulation,
                                schools: schoolResponse.data.data,
                            }))
                        )
                    ).then((postulationsWithSchools) => ({
                        ...item.prescription,
                        postulations: postulationsWithSchools,
                    }))
                );
                return Promise.all(schoolPromises);
            })
            .then((prescriptionsWithPostulationsAndSchools) => {
                setPrescriptions(prescriptionsWithPostulationsAndSchools);
                feedbackApp.showFeedback({
                    title: '¡Postulaciones Encontradas! 🎉  Es muy importante que mantengas los datos de tu preinscripción seguros.',
                    severity: 'success',
                });
                setLoading(false);
                setSubmitting(false);
            })
            .catch((error) => {
                setLoading(false);
                setSubmitting(false);
                feedbackApp.showFeedback({
                    title: 'No se encontraron preinscripciones con la información ingresada.',
                    severity: 'error',
                });
            });
    };

    const getStatusInfo = (status) => {
        switch (status) {
            case 1:
                return {
                    percentage: 25,
                    text: 'Por determinar',
                    color: '#FFBF43',
                    icon: YellowSchoolIcon
                };
            case 2:
                return {
                    percentage: 100,
                    text: 'Aceptada',
                    color: '#00C483',
                    icon: GreenSchoolIcon
                };
            case 3:
                return {
                    percentage: 100,
                    text: 'Rechazada',
                    color: '#ff6060',
                    icon: RedSchoolIcon
                };
            default:
                return {
                    percentage: 0,
                    text: 'Desconocido',
                    color: 'gray',
                    icon: BlueSchoolIcon
                };
        }
    };


    const getChipText = (priority) => {
        switch (priority) {
            case 1:
                return 'Primera Opción 🥇';
            case 2:
                return 'Segunda Opción 🥈';
            case 3:
                return 'Tercera Opción 🥉';
            default:
                return '';
        }
    };

    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2, alignItems: 'center' }}>
            <Typography variant="h5" align="center" gutterBottom style={{ color: '#919399' }}>
                ¡Bienvenido/a al seguimiento de tu preinscripción! 🎉
            </Typography>
            <Divider />
            <Typography variant="subtitle1" gutterBottom className="py-2" style={{ color: '#757575', fontSize: '1.1rem' }}>
                - Debes de contar con tu{' '}
                <span style={{ color: '#000', fontWeight: 'bold' }}>Folio de preinscripción</span>{' '}
                el cual obtuviste al momento de terminar el proceso de preinscripción
                <br />- Esta sección es para consultar el estado de tu preinscripción.
            </Typography>
            <Divider />

            <Box className="mt-4">
                <Formik initialValues={{ identifier: uuid || '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ errors, touched, isValid, isSubmitting }) => (
                        <Form>
                            {/* Campo de texto siempre visible */}
                            <Box display="flex" alignItems="center" gap={2}>
                                <Field
                                    name="identifier"
                                    as={TextField}
                                    variant="outlined"
                                    color="primary"
                                    label="Ingresa tu folio de seguimiento o número de teléfono"
                                    error={Boolean(errors.identifier && touched.identifier)}
                                    helperText={errors.identifier && touched.identifier ? errors.identifier : ''}
                                    fullWidth
                                    margin="none"
                                    sx={{ height: '3.5rem' }}
                                    disabled={isSubmitting}
                                />
                                {/* Botón "Consultar" (solo visible en pantallas grandes) */}
                                {!isMobile && (
                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !isValid}
                                        startIcon={<UploadFileTwoTone />}
                                        sx={{ height: '3.5rem', minWidth: '9.375rem' }}
                                    >
                                        {isSubmitting ? 'Procesando...' : 'Consultar'}
                                    </LoadingButton>
                                )}
                            </Box>

                            {/* Botón "Regresar" (visible solo en pantallas grandes) */}
                            {!isMobile && (
                                <Box className="mt-5">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        sx={{ height: '3.5rem' }}
                                        onClick={() => setShowContainer('inicio')}
                                    >
                                        Regresar
                                    </Button>
                                </Box>
                            )}

                            {/* Botones en pantallas pequeñas */}
                            {isMobile && (
                                <Box display="flex" alignItems="center" gap={2} className="mt-5">
                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !isValid}
                                        startIcon={<UploadFileTwoTone />}
                                        sx={{ height: '3.5rem', minWidth: '9.375rem', flex: 1 }}
                                    >
                                        {isSubmitting ? 'Procesando...' : 'Consultar'}
                                    </LoadingButton>

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ height: '3.5rem', minWidth: '9.375rem', flex: 1 }}
                                        onClick={() => setShowContainer('inicio')}
                                    >
                                        Regresar
                                    </Button>
                                </Box>
                            )}
                        </Form>
                    )}
                </Formik>
            </Box>


            <Divider />

            <Skeleton loading={loading} avatar active />

            <Space direction="vertical" size="middle" loading={loading} style={{ display: 'flex' }}></Space>
            <Space className="w-full" direction="vertical">
                {Prescriptions.map((prescription, index) => (
                    <div key={index}>
                        <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="stretch" gap={4} justifyContent="center">
                            <Skeleton loading={loading} avatar active>
                                <Box flex={1} p={2} className="rounded-xl shadow-lg" sx={{ minWidth: isMobile ? "100%" : 500, maxWidth: isMobile ? "100%" : 500 }}>
                                    <Box display="flex" justifyContent="center" mb={1}>
                                        <Typography variant="h6" className="font-semibold" style={{ color: "#8c8c8c" }} m={1}>
                                            Información del Alumno/a
                                        </Typography>
                                    </Box>
                                    <Meta
                                        avatar={<Avatar src={childrenIcon} className="ml-3 mt-2 !w-14 !h-14" sx={{ width: 65, height: 65 }} />}
                                        title={
                                            <Typography variant="h6" className="font-semibold" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                {prescription.name} {prescription.last_name} {prescription.second_last_name}
                                            </Typography>
                                        }
                                        description={
                                            <Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">Grado:</Typography>
                                                    <Typography variant="body2">{prescription.grade ? prescription.grade : "N/A"}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">CURP:</Typography>
                                                    <Typography variant="body2">{prescription.curp ? prescription.curp : "N/A"}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">Dirección:</Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.home_street}, #{prescription.home_external_number}, {prescription.home_zipcode}, {prescription.home_neighborhood}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Skeleton>

                            <Skeleton loading={loading} avatar active>
                                <Box flex={1} p={2} className="rounded-xl shadow-lg" sx={{ minWidth: isMobile ? "100%" : 500, maxWidth: isMobile ? "100%" : 500 }}>
                                    <Box display="flex" justifyContent="center" mb={1}>
                                        <Typography variant="h6" className="font-semibold" style={{ color: "#8c8c8c" }} m={1}>
                                            Información de Contacto
                                        </Typography>
                                    </Box>
                                    <Meta
                                        avatar={<AccountCircleIcon className="text-gray-200 h-20 w-20" />}
                                        title={
                                            <Typography variant="h6" className="font-semibold" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                {prescription.parent_name} {prescription.parent_last_name} {prescription.parent_second_last_name}
                                            </Typography>
                                        }
                                        description={
                                            <Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">Teléfono:</Typography>
                                                    <Typography variant="body2">{prescription.parent_phone}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">Email:</Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.parent_email}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">Dirección:</Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.parent_home_street}, #{prescription.parent_home_external_number}, {prescription.parent_home_neighborhood}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Skeleton>
                        </Box>

                        <Divider sx={{ width: "100%", margin: isMobile ? "16px 0" : "24px 0" }} />


                        <Space
                            direction={isMobile ? "vertical" : "horizontal"}
                            size="middle"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                alignItems: "stretch",
                                flexWrap: isMediumScreen ? "wrap" : "nowrap"
                            }}
                        >
                            {prescription.postulations.map((postulation, postIndex) => (
                                <Card
                                    key={postIndex}
                                    hoverable
                                    style={{
                                        marginBottom: 16,
                                        minWidth: isMobile ? "100%" : isMediumScreen ? 500 : 600,
                                        maxWidth: isMobile ? "100%" : isMediumScreen ? 550 : 600,
                                        width: "100%",
                                    }}
                                    loading={loading}
                                    className="rounded-xl overflow-hidden shadow-lg w-full"
                                    cover={
                                        loading ? (
                                            <Skeleton.Image className="w-full" style={{ height: 300 }} />
                                        ) : (
                                            <>
                                                {postulation.schools.latitude && postulation.schools.longitude && (
                                                    <Box className="absolute top-2 bg-white m-1 rounded-xl shadow-lg z-10 left-1/2 transform -translate-x-1/2 w-10/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-5/12">
                                                        <Typography variant="body2" className="font-bold" align="center" mt={0.5}>
                                                            Dirección de la escuela
                                                        </Typography>
                                                        <Typography variant="body2" align="center">
                                                            {postulation.schools.street}, #{postulation.schools.inside_number}, {postulation.schools.zipcode}, {postulation.schools.location}
                                                        </Typography>
                                                        <Box display="flex" justifyContent="center" mt={0.1}>
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                onClick={() => {
                                                                    const url = `https://www.google.com/maps/dir/?api=1&destination=${postulation.schools.latitude},${postulation.schools.longitude}`;
                                                                    window.open(url, '_blank');
                                                                }}
                                                            >
                                                                Ver indicaciones de llegada
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {postulation.schools.latitude && postulation.schools.longitude && (
                                                    <Map
                                                        height={isMobile ? 150 : 250}
                                                        style={{ paddingTop: "50px" }}
                                                        defaultCenter={[
                                                            (postulation.schools.latitude || 24.0226) + 0.0025,
                                                            (postulation.schools.longitude || -104.6532) - 0.0005,
                                                        ]}
                                                        mouseEvents={true}
                                                        touchEvents={true}
                                                        zoomSnap={true}
                                                        zoomDelta={0}
                                                        defaultZoom={15}
                                                    >
                                                        {postulation.schools.longitude && postulation.schools.latitude && (
                                                            <Marker
                                                                width={50}
                                                                anchor={[
                                                                    postulation.schools.latitude,
                                                                    postulation.schools.longitude,
                                                                ]}
                                                            >
                                                                <img src={getStatusInfo(postulation.status).icon} width={150} height={155} alt="marker" />

                                                            </Marker>
                                                        )}
                                                    </Map>
                                                )}
                                            </>
                                        )
                                    }
                                    actions={[
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            key="actions"
                                            sx={{ flexDirection: isMobile ? "column" : "row" }}
                                        >
                                            {postulation.status === 2 && (
                                                <Tooltip title="Descargar Ficha de Preinscripción en PDF" arrow>
                                                    <IconButton
                                                        onClick={() => handleExportPDF(postulation.postulation_id)}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            margin: -1,
                                                            width: 'auto',
                                                            height: 'auto',
                                                            padding: 0,
                                                            borderRadius: 2,
                                                            transition: 'all 0.3s ease',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                                borderRadius: 0,
                                                                width: 'auto',
                                                                height: 'auto',
                                                            },
                                                        }}
                                                    >
                                                        <FileDownloadOutlinedIcon style={{ width: 40, height: 40 }} />
                                                        <Typography variant="body2" className="font-bold" sx={{ marginTop: 1 }}>
                                                            Descarga tu Ficha de Preinscripción
                                                        </Typography>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>,
                                    ]}
                                >
                                    <Box display="flex" alignItems="center" gap={3} sx={{ margin: -2, marginLeft: 1 }}>
                                        <Meta
                                            avatar={
                                                <Avatar
                                                    sx={{
                                                        width: "40px",
                                                        height: "40px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        overflow: "visible"
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={getStatusInfo(postulation.status).icon}
                                                        sx={{
                                                            width: "140px",
                                                            height: "150px",
                                                            marginLeft: "-19.5px",
                                                            marginTop: "-4px",
                                                            position: "absolute"
                                                        }}
                                                    />
                                                </Avatar>


                                            }

                                            title={
                                                <Box display="flex">
                                                    <Box sx={{ maxWidth: "70%", whiteSpace: "normal", wordBreak: "break-word" }}>
                                                        <Typography variant="h6" className="font-bold mr-5">
                                                            {postulation.schools.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box display="flex">
                                                        {prescription.postulations
                                                            .filter(currentPostulation => currentPostulation.school_id === postulation.school_id)
                                                            .map(filteredPostulation => (
                                                                <Chip
                                                                    key={filteredPostulation.postulation_id}
                                                                    label={getChipText(filteredPostulation.priority)}
                                                                    sx={{
                                                                        backgroundColor: "white",
                                                                        borderColor: "#005A99",
                                                                        color: "#003F66",
                                                                        borderWidth: "1px",
                                                                        borderStyle: "solid",
                                                                    }}
                                                                />
                                                            ))}
                                                    </Box>
                                                </Box>
                                            }
                                            description={
                                                <Box marginTop={1.5} marginBottom={1} width="100%">
                                                    <Box display={"flex"} gap={1} marginBottom={1}>
                                                        <Typography variant="body2" className="font-bold">CCT:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3">{postulation.schools.clave}</Typography>
                                                        <Typography variant="body2" className="font-bold">Teléfono:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3">{postulation.schools.cellphone}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} gap={1} marginBottom={1}>
                                                        <Typography variant="body2" className="font-bold">Nivel:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3">{getLevelName(postulation.schools.level)}</Typography>
                                                        <Typography variant="body2" className="font-bold">Turno:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3">{getTurnLevel(postulation.schools.turn)}</Typography>
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <Divider />
                                    <Box style={{ marginTop: -20, marginLeft: 1 }}>
                                        <Typography variant="h7" className="font-medium">Proceso de aceptación</Typography>
                                        <Box
                                            key={postulation.postulation_id}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <Typography variant="body2" style={{ color: getStatusInfo(postulation.status).color }}>
                                                {getStatusInfo(postulation.status).text}
                                            </Typography>
                                            <Progress
                                                percent={getStatusInfo(postulation.status).percentage}
                                                showInfo={false}
                                                strokeColor={getStatusInfo(postulation.status).color}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            ))}
                        </Space>
                    </div>
                ))}
            </Space>
        </Box>
    );
};