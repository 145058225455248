import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/NavBar';
import {
    Box,
    Container,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    styled,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

//icons
import check from '../../assets/check.svg';
import directivo from '../../assets/directivo.png';
import YellowSchoolIcon from '../../assets/iconos/Escuela_Amarillo.svg';
import BlueSchoolIcon from '../../assets/iconos/Escuela_Azul.svg';
import PinkSchoolIcon from '../../assets/iconos/Escuela_Rosa.svg';

//components
import { PreInscriptionMessage } from './pages/message';
import { FooterInfo } from '../../Components/FooterInfo';
import { getStepContent } from './components/getStepContent';
import { Following } from './pages/Following';
import { UuidMessage } from './pages/UuidMessage';
import { useShowContainer } from '../../services/Container';

export const PreRegistrations = () => {
    ///////////STATES////////////

    const [activeStep, setActiveStep] = useState(0);
    const [uuid, setUuid] = useState('');

    const steps = [
        'Inicio',
        'Datos del alumno',
        'Datos de contacto',
        'Documentos',
        'Postulaciones',
        'Finalizar',
    ];

    const [formData, setFormData] = useState({
        preinscriptionLevel: '',
        student: {},
        parent: {},
        archives: {},
        postulations: [
            {
                priority: 1,
                label: 'Alta',
                school: {},
                inputValue: '',
                options: [],
                loading: false,
                siblings_number: 0,
                icon: YellowSchoolIcon,
            },
            {
                priority: 2,
                label: 'Media',
                school: {},
                inputValue: '',
                options: [],
                loading: false,
                siblings_number: 0,
                icon: BlueSchoolIcon,
            },
            {
                priority: 3,
                label: 'Baja',
                school: {},
                inputValue: '',
                options: [],
                loading: false,
                siblings_number: 0,
                icon: PinkSchoolIcon,
            },
        ],
    });

    ///////////STYLES////////////
    const CustomConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            display: 'none',
        },
        '&.Mui-active .MuiStepConnector-line': { display: 'none' },
        '&.Mui-completed .MuiStepConnector-line': { display: 'none' },
    }));

    const CustomStepBar = styled('div')(({ active, completed, theme }) => ({
        width: '10rem',
        height: '0.8rem',
        borderRadius: '10px',
        backgroundColor: completed ? '#00C483' : active ? '#00C483' : '#D0E2FF',
        position: 'absolute',
        top: '1.4rem',
        transform: 'translate(-45%, 20%)',
        [theme.breakpoints.down('md')]: {
            width: '5rem',
            height: '0.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            width: '2.2rem',
            height: '0.3rem',
        },
    }));

    const CustomStepDot = styled('div')(({ active, completed, theme }) => ({
        width: '.5rem',
        height: '.5rem',
        borderRadius: '50%',
        backgroundColor: completed ? '#00C483' : active ? '#00C483' : '#D0E2FF',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 10,
        transform: 'translate(-50%, -20%)',
    }));

    const CustomStepLabel = styled(StepLabel)(
        ({ theme, label, stepIndex }) => ({
            display: 'flex',
            alignItems: 'center',
            '& .MuiStepLabel-label': {
                fontSize: '0.9rem',
                color: '#6F6F6F',
                textAlign: 'center',
                position: 'relative',
                top:
                    stepIndex === 0 ||
                    stepIndex === 1 ||
                    stepIndex === 2 ||
                    stepIndex === 3 ||
                    stepIndex === 4 ||
                    stepIndex === 5
                        ? '-3rem'
                        : '-4rem',
                marginBottom: theme.spacing(0),
                '&.Mui-active': {
                    color: '#6F6F6F',
                    fontWeight: 'semibold',
                },
                '&.Mui-completed': {
                    color: '#6F6F6F',
                    fontWeight: 'semibold',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '0.6rem',
                    top:
                        stepIndex === 3 || stepIndex === 4 || stepIndex === 5
                            ? '-2rem'
                            : '-3rem',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.5rem',
                    top:
                        stepIndex === 3 || stepIndex === 4 || stepIndex === 5
                            ? '-2rem'
                            : '-4rem',
                },
            },
        })
    );

    ///////////SHARED STATES////////////

    ///////////HOOKS////////////
    const { showContainer, setShowContainer } = useShowContainer();

    ///////////FUNCTIONS////////////
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleBack = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        activeStep === 0
            ? setShowContainer('inicio')
            : setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    ///////////USE EFFECTS////////////
    useEffect(() => {
        if (showContainer === 'inicio') {
            setActiveStep(0);
            setUuid(uuidv4());
            localStorage.removeItem('uploadedFiles');
            setFormData({
                preinscriptionLevel: '',
                student: {},
                parent: {},
                archives: {},
                postulations: [
                    {
                        priority: 1,
                        label: 'Primera opción',
                        school: {},
                        inputValue: '',
                        options: [],
                        loading: false,
                        siblings_number: 0,
                        icon: YellowSchoolIcon,
                    },
                    {
                        priority: 2,
                        label: 'Segunda opción',
                        school: {},
                        inputValue: '',
                        options: [],
                        loading: false,
                        siblings_number: 0,
                        icon: BlueSchoolIcon,
                    },
                    {
                        priority: 3,
                        label: 'Tercera opción',
                        school: {},
                        inputValue: '',
                        options: [],
                        loading: false,
                        siblings_number: 0,
                        icon: PinkSchoolIcon,
                    },
                ],
            });
        }
    }, [showContainer]);

    ///////////RENDER////////////

    return (
        <>
            <NavBar />
            <Box className="pre-inscription">
                <Box className="h-96 bg-gradient-to-b from-dark_grey-500 from-60% via-dark_grey-400 to-white">
                    <div className="flex items-center justify-center pt-40">
                        <div className="flex items-center justify-center">
                            <img src={check} alt="check" className="w-8 h-8" />

                            <div className="relative inline-block">
                                <h1 className="text-4xl font-bold text-white ml-2">
                                    Preinscripciones
                                </h1>
                                <div className="absolute top-[-20px] right-[-100px] bg-amber-500 text-white px-3 py-1 rounded-lg transform rotate-6 shadow-lg tracking-wide font-semibold ">
                                    🚀 Versión en prueba
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center pt-5">
                        <img
                            src={directivo}
                            className="w-32 md:w-48 lg:w-56 ml-5"
                            alt="directivo"
                        />
                    </div>
                    <div className="py-s">
                        {showContainer === 'inicio' && (
                            <>
                                <PreInscriptionMessage
                                    setShowContainer={setShowContainer}
                                />
                            </>
                        )}
                        {showContainer === 'seguimiento' && <Following />}
                        {showContainer === 'pre-inscribirte' && (
                            <Container>
                                <Box
                                    sx={{
                                        paddingBottom: '5rem',
                                    }}
                                >
                                    <UuidMessage
                                        level={formData.preinscriptionLevel}
                                        activeStep={activeStep}
                                        uuid={uuid}
                                    />
                                </Box>
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    connector={<CustomConnector />}
                                >
                                    {steps.map((label, index) => (
                                        <Step sx={{ m: 1 }} key={label}>
                                            <CustomStepLabel
                                                stepIndex={index}
                                                StepIconComponent={(props) => (
                                                    <>
                                                        <CustomStepBar
                                                            {...props}
                                                            active={
                                                                activeStep ===
                                                                index
                                                            }
                                                            completed={
                                                                activeStep >
                                                                index
                                                            }
                                                        />
                                                        <CustomStepDot
                                                            active={
                                                                activeStep ===
                                                                index
                                                            }
                                                            completed={
                                                                activeStep >
                                                                index
                                                            }
                                                        />
                                                    </>
                                                )}
                                            >
                                                {label}
                                            </CustomStepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                                <Box>
                                    {getStepContent(
                                        activeStep,
                                        formData,
                                        setFormData,
                                        steps,
                                        handleBack,
                                        handleNext,
                                        uuid
                                    )}
                                </Box>
                            </Container>
                        )}
                    </div>
                    <FooterInfo />
                </Box>
            </Box>
        </>
    );
};
