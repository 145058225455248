
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';
import { useShowContainer } from '../../../../../services/Container';
import { useFeedback } from '../../../../../../hooks';
import Feedback from '../../../../../../service/Feedback';


export default function CloseDialog({ open, handleClose, uuid, setRespData }) {

    const history = useHistory();

    const { showContainer, setShowContainer, setuuid } = useShowContainer();

    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const descargar = () => {
        try {
            const url = window.URL.createObjectURL(new Blob([setRespData.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `solicitud_de_preincripcion.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            let feedbackError = FeedbackService.getMessage(error);

            feedbackApp.showFeedback({
                title: feedbackError.title,
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: 'white',
                    textAlign: 'center',
                    fontSize: 20,
                }}
            >

                {"¡Registro exitoso!"}
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: 0,
                }}
            >
                <DialogContentText
                    sx={{
                        backgroundColor: '#fff3cd',
                        color: '#856404',
                        padding: 2,
                        textAlign: 'center'
                    }}
                >

                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Avatar sx={{ bgcolor: green[500], width: 80, height: 80 }}>
                            <CheckCircleOutlineIcon style={{ fontSize: 50 }} />
                        </Avatar>
                    </Box>

                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                        Preincripcion finalizada.
                    </Typography>

                </DialogContentText>
            </DialogContent>

            

                <center>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, maxWidth: 800 }}>
                        <Button
                            autoFocus
                            size="small"
                            color="success"
                            
                            onClick={() => {
                                descargar()
                            }}
                        >
                            Descargar
                        </Button>

                        <Button
                            autoFocus
                            size="small"
                            color="error"
                            
                            onClick={() => {

                                setShowContainer("seguimiento")

                                setuuid(uuid)

                                handleClose()
                            }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </center>
        </Dialog>
    )
}