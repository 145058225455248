import {} from '@mui/material';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmationModal } from '../Components/ConfirmationModal';

const ShowContainerContext = createContext();

export const ShowContainerProvider = ({ children }) => {
    /////////////////////// LOCAL STATE ///////////////////////////

    const [showContainer, setShowContainer] = useState('inicio');
    const [uuid, setuuid] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    /////////////////////// HOOKS ///////////////////////////

    const history = useHistory();

    /////////////////////// ACTIONS ///////////////////////////

    const handleContainerChange = (newContainer, targetPath) => {
        const isNavigatingToHome = newContainer === 'inicio';
        const isInPreInscription = showContainer === 'pre-inscribirte';

        if (isInPreInscription && isNavigatingToHome) {
            setShowConfirmModal(true);
            setPendingNavigation({ container: 'inicio', path: targetPath });
            return;
        }

        setShowContainer(newContainer);
        if (targetPath) {
            history.push(targetPath);
        }
    };

    const handleConfirm = () => {
        setShowContainer(pendingNavigation.container);
        if (pendingNavigation.path) {
            history.push(pendingNavigation.path);
        }
        setShowConfirmModal(false);
        setPendingNavigation(null);
    };

    const handleCancel = () => {
        setShowConfirmModal(false);
        setPendingNavigation(null);
    };
    /////////////////////// EFFECTS ///////////////////////////

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (showContainer === 'pre-inscribirte') {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () =>
            window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [showContainer]);

    /////////////////////// RENDER ///////////////////////////

    return (
        <>
            <ShowContainerContext.Provider
                value={{
                    showContainer,
                    setShowContainer: handleContainerChange,
                    uuid,
                    setuuid,
                }}>
                {children}
            </ShowContainerContext.Provider>

            <ConfirmationModal
                open={showConfirmModal}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </>
    );
};

export const useShowContainer = () => useContext(ShowContainerContext);
