import { DateTime } from 'luxon';
import * as constants from './../service/const';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

/**
 * Retorna el nombre del nivel dependiando del parametro
 *
 * @param {*} level
 * @returns
 */
function getLevelName(level) {
    if (!level) {
        return 'Sin nivel';
    }
    let levelName = constants.levelsNames.find((item) => item.key == level);

    if (!levelName) {
        return 'Sin nivel';
    }

    return levelName.title;
}

/**
 * Retorna el nombre del turno dependiando del parametro
 *
 * @param {*} turn
 * @returns
 */
function getTurnLevel(turn) {
    if (!turn) {
        return 'Sin turno';
    }
    let levelName = constants.turnsNames.find((item) => item.key == turn);

    return levelName.title;
}

/**
 * Retorna el nombre espacial dependiando del parametro
 *
 * @param {*} turn
 * @returns
 */
function getSpecialName(special) {
    if (special == null) {
        return 'Sin valor';
    }
    let groupTypeName = constants.specialNames.find(
        (item) => item.key == special
    );

    return groupTypeName.title;
}

/**
 * Retorna el nombre espacial dependiando del parametro
 *
 * @param {*} turn
 * @returns
 */
function getRelationships(key) {
    if (key == null) {
        return 'Sin valor';
    }
    let levelName = constants.relationShipsNames.find(
        (item) => item.key == key
    );

    return levelName;
}

/**
 * Funcion para obtener el tiempo a expirar random
 */

function getExpireIn(key) {
    const time = constants[key];
    const randomInt = Math.floor(Math.random() * 10) + 1;
    const addOrSubtract = Math.random() < 0.5 ? -1 : 1;
    const adjustedTime = time + randomInt * addOrSubtract;

    return new Date().setMinutes(new Date().getMinutes() + adjustedTime);
}

/**
 * Retorna el elemento basandoce en el tipo de noticia
 *
 * @param {*} turn
 * @returns
 */
function getNoticeTypeItem(type) {
    if (!type) {
        return {
            title: 'Sin tipo',
        };
    }
    let item = constants.NOTICE_ITEMS.find((item) => item.key == type);

    return item;
}

/**
 * Retorna el rol del usuario
 */

function getUserRole(role) {
    // if(!role){
    //     return 'Sin rol'
    // }
    // console.log(role, 'role');

    // let roleName = constants.userRoles.find((item) => item.key === role)

    // return roleName.title

    const userRole = [];

    for (const [rol, valor] of Object.entries(constants.roles)) {
        if (role.charAt(constants.roles[rol].indexOf('1')) === '1') {
            let color;
            switch (rol) {
                case 'Padre':
                case 'Tutor':
                    color = 'green';
                    break;
                case 'Profesor':
                    color = 'yellow';
                    break;
                case 'Orientador':
                    color = 'orange';
                    break;
                case 'Supervisor':
                    color = 'blue';
                    break;
                case 'Director':
                    color = 'black';
                    break;
                case 'Administrador':
                    color = 'cyan';
                    break;
                default:
                    color = 'default';
            }
            userRole.push(rol);
        }
    }
    return userRole;
}

function getUserRoles(role) {
    // if(!role){
    //     return 'Sin rol'
    // }
    // console.log(role, 'role');

    // let roleName = constants.userRoles.find((item) => item.key === role)

    // return roleName.title

    const userRole = [];

    for (const [rol, valor] of Object.entries(constants.rolesEnglish)) {
        if (role.charAt(constants.rolesEnglish[rol].indexOf('1')) === '1') {
            let color;
            switch (rol) {
                case 'PARENT':
                case 'TUTOR':
                    color = 'green';
                    break;
                case 'PROFESSOR':
                    color = 'yellow';
                    break;
                case 'ASSESSOR':
                    color = 'orange';
                    break;
                case 'SUPERVISOR':
                    color = 'blue';
                    break;
                case 'DIRECTOR':
                    color = 'black';
                    break;
                case 'ADMINISTER':
                    color = 'cyan';
                    break;
                default:
                    color = 'default';
            }
            userRole.push(rol);
        }
    }
    return userRole;
}

/**
 * Retorna el elemento basandoce en el tipo de noticia
 *
 * @param {*} turn
 * @returns
 */
function getNoticeStatusItem(type) {
    if (!type) {
        return {
            title: 'Sin status',
            icon: <BrokenImageIcon />,
        };
    }
    let item = constants.NOTICE_STATUS_ITEMS.find((item) => item.key == type);

    return item;
}

/**
 * Truncar valores
 *
 * @param valor
 */
function getCalificationRound(number, posiciones = 0) {
    let numberFormated = number;

    var s = number.toString();
    var l = s.length;

    if (s.indexOf('.') > 0) {
        var decimalLength = s.indexOf('.') + 1;
        numberFormated = s.substr(0, decimalLength + posiciones);
    }

    return Number(numberFormated);
}

const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
};

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

const saveState = (name, state) => {
    try {
        let serializedData = JSON.stringify(state);
        localStorage.setItem(name, serializedData);
    } catch (error) {
        console.log(error);
        // Acá podemos capturar o crear cualquier log que deseemos en caso de que falle el salvado en el storage.
    }
};

let round = (number, posiciones = 0) => {
    let numberFormated = number;

    let s = number.toString();
    //let l = s.length

    if (s.indexOf('.') > 0) {
        let decimalLength = s.indexOf('.') + 1;
        numberFormated = s.substr(0, decimalLength + posiciones);
    }

    return Number(numberFormated);
};

const parentescoCheck = (number) => {
    const parentesco = constants.parentesco.find(
        (parentesco) => parentesco.value == number
    );
    return parentesco;
};

const nacionalityCheck = (number) => {
    const nacionality = constants.nacionality.find(
        (nacionality) => nacionality.value == number
    );
    return nacionality;
};

const genderCheck = (number) => {
    const gender = constants.gender.find((gender) => gender.value == number);
    return gender;
};

const priorityCheck = (number) => {
    const priority = constants.priority.find(
        (priority) => priority.value == number
    );
    return priority;
};


const formatDateInSpanish = (dateString) => {
    const dt = DateTime.fromFormat(dateString, 'dd-MM-yyyy HH:mm:ss').setLocale(
        'es'
    );
    const formattedDate = dt.toFormat("dd 'de' LLLL 'del' yyyy HH:mm 'horas'");

    return formattedDate.replace(/\b(\w)/g, (char) => char.toUpperCase());
};

const gradeCheck = (number) => {
    const priority = constants.grade.find(grade => grade.value == number);
    return priority
}

export {
    getLevelName,
    getTurnLevel,
    getCalificationRound,
    saveState,
    removeDuplicates,
    convertArrayToObject,
    getSpecialName,
    getNoticeTypeItem,
    getNoticeStatusItem,
    getRelationships,
    round,
    getUserRole,
    getUserRoles,
    getExpireIn,
    parentescoCheck,
    nacionalityCheck,
    genderCheck,
    priorityCheck,
    formatDateInSpanish,
    gradeCheck
};
