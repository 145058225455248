import { Box, Button, Stack, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import {
    invalidate,
    loadUI,
    selectStatusServer,
} from '../../store/slices/settingsUI';
import { Error } from '../../components/Feedback';
import SchoolGeneralDataCard from '../../components/SchoolGeneralDataCard';
import { SchoolImageContainer } from './components/SchoolImageContainer';
import { MainImageContainer } from './components/MainImageContainer';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './components/SkeletonPage';
import MakeSnaphotModal from './components/MakeSnaphotModal';
import useFeedback from '../../hooks/useFeedback';
import PartialsCard from './components/PartialsCard';
import NotificationsSettingsCard from './components/NotificationsSettingsCard';
import ThemeSettingsCard from './components/ThemeSettingsCard';
import ImportsCard from './components/ImportsCard';
import FeatureFlags from '../../service/FeatureFlags';
import CycleCutsCard from './components/CycleCutsCard';
import { SchoolLocation } from './components/SchoolUbication';

/**
 * Pagina de configuraciones para una escuela
 *
 * @returns
 */
const Configuraciones = () => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();

    //////////////// SHARED STATE ///////////////////

    const statusServer = useSelector(selectStatusServer);

    const featureFlagCycles =
        FeatureFlags.isFeatureFlagActive('NEW_SNAPSHOT_TABLE');

    ///////////////// LOCAL STATE ///////////////////

    const [showMakeSnaphotModal, setShowMakeSnaphotModal] = useState(false);

    ///////////// ACTIONS /////////////////

    /**
     * Funcion para recargar los datos de la UI
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Invalida y recargar la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Mostrar modal para crear un corte de ciclo
     */
    const onShowMakeSnaphotModal = () => {
        setShowMakeSnaphotModal(true);
    };

    /**
     * Ejecutado cada ves que ingresamos a la pagina
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, []);

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {showMakeSnaphotModal && (
                <MakeSnaphotModal
                    open={showMakeSnaphotModal}
                    setOpen={setShowMakeSnaphotModal}
                />
            )}

            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Stack
                        direction={'row'}
                        alignItems={'flex-end'}
                        justifyContent={'flex-end'}
                        spacing={2}
                        sx={{ mb: 2 }}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={4}>
                            <SchoolImageContainer />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <MainImageContainer />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <ThemeSettingsCard />
                        </Grid>
                    </Grid>
                    <SchoolLocation />

                    <NotificationsSettingsCard />
                    <SchoolGeneralDataCard />

                    <PartialsCard />
                    <ImportsCard />
                    <CycleCutsCard />
                </>
            )}
        </Box>
    );
};

export default Configuraciones;
